import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
// import Text from 'common/src/components/Text';
// import Heading from 'common/src/components/Heading';
// import Container from 'common/src/components/UI/Container';
import Image from 'common/src/components/Image';

import { useStaticQuery, graphql } from 'gatsby';
// import { Link } from 'gatsby';

import '../../iconfloating.css';

const IconFloatingSolutionCapability = ({
  sectionWrapper,
  row,
  col,
  col20,
  col30,
  col70,
  iconHead,
  iconsubHead,
  iconDescription,
  textArea,
  name,
}) => {
  const Data = useStaticQuery(
    graphql`
      query {
        hostingJson {
          SOLUTIONS_CAPABILITY_ICON2 {
            BFSI {
              Category
              Items {
                title
                description
                avatar {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            CrossIndustry {
              Category
              Items {
                title
                description
                avatar {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            CustomerService {
              Category
              Items {
                title
                description
                avatar {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            Finance {
              Category
              Items {
                title
                description
                avatar {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            Healthcare {
              Category
              Items {
                title
                description
                avatar {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            Hospitality {
              Category
              Items {
                title
                description
                avatar {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            IT {
              Category
              Items {
                title
                description
                avatar {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            Logistics {
              Category
              Items {
                title
                description
                avatar {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            Operations {
              Category
              Items {
                title
                description
                avatar {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            RealEstate {
              Category
              Items {
                title
                description
                avatar {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            SharedServices {
              Category
              Items {
                title
                description
                avatar {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return (
    <>
      <Box
        {...sectionWrapper}
        className={Data.hostingJson.SOLUTIONS_CAPABILITY_ICON2[name].Category}
      >
        <Fade bottom cascade delay={10}>
          <Box {...row} className="margin_auto flexjustifyaround">
            {Data.hostingJson.SOLUTIONS_CAPABILITY_ICON2[name].Items.map(
              (item, index) => (
                <Box
                  {...col30}
                  key={`capability-${index}`}
                  className="icontext_card icontext_card_whitebg"
                >
                  <Box className="flexaligncenter">
                    <ul>
                      <li>
                        <Image
                          src={item.avatar.childImageSharp.fluid.src}
                          alt={`${item.title} from RAP`}
                          className="icon_image"
                        />
                      </li>
                      <li {...iconHead}>{item.title}</li>
                      <li {...iconDescription}>{item.description}</li>
                    </ul>
                  </Box>
                </Box>
              )
            )}
          </Box>
        </Fade>
      </Box>
    </>
  );
};

IconFloatingSolutionCapability.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col1: PropTypes.object,
  col: PropTypes.object,
  col30: PropTypes.object,
  iconHead: PropTypes.object,
  iconsubHead: PropTypes.object,
  iconDescription: PropTypes.object,
  textArea: PropTypes.object,
};

IconFloatingSolutionCapability.defaultProps = {
  sectionWrapper: {
    as: 'div',
    id: 'IconFloating_section',
    className: 'IconFloating_section',
    pt: ['15px', '15px', '15px', '15px'],
    pb: ['15px', '15px', '15px', '15px'],
    background: 'transparent',
    // pb: ['60px', '80px', '80px', '100px'],
  },

  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  col: {
    className: 'col',
    width: [1, 0.45, 0.45, 0.45],
    pr: '15px',
    pl: '15px',
    mb: '20px',
  },
  col20: {
    className: 'col20',
    width: [0.4, 0.15, 0.15, 0.2],
  },
  col30: {
    className: 'col30',
    width: [1, 0.47, 0.47, 0.3],
  },
  col70: {
    className: 'col70',
    width: [1, 0.45, 0.7, 0.7],
  },
  iconHead: {
    fontSize: ['18px', '18px', '20px', '20px'],
    className: 'iconHead',
  },
  iconsubHead: {
    fontSize: ['18px', '18px', '20px', '20px'],
    className: 'iconsubHead',
  },
  textArea: {
    width: ['100%', '85%', '85%', '85%', '80%'],
    margin: '0 auto',
  },
  iconDescription: {
    className: 'iconDescription',
    fontSize: ['18px', '18px', '20px', '20px'],
  },
};

export default IconFloatingSolutionCapability;
