import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Card from 'common/src/components/Card';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';

import { useStaticQuery, graphql, Link } from 'gatsby';

import IconFloatingSolutionCapability from '../IconFloating/Solution/capability';
import IconFloatingSolutionBenefit from '../IconFloating/Solution/benefits';
import BgImageDark from '../BgImagedark';

import sample from 'common/src/assets/image/hosting/author-3.jpg';

import './infosolution.css';

const InfoSolutionSection = ({
  name,
  sectionWrapper,
  row,
  col,
  title,
  description,
  button,
  textArea,
  contentArea,
  imageArea,
  imageAreaRow,
  imageWrapper,
  imageWrapperOne,
  imageWrapperTwo,
  secTitleWrapper,
  secHeading,
  secText,
  box_size,
}) => {
  const Data = useStaticQuery(
    graphql`
      query {
        hostingJson {
          INFO_SOLUTION2 {
            IT {
              description
              class
            }
            CustomerService {
              description
              class
            }
            Finance {
              description
              class
            }
            Operations {
              description
              class
            }
            BFSI {
              description
              class
            }
            Logistics {
              description
              class
            }
            Healthcare {
              description
              class
            }
            RealEstate {
              description
              class
            }
            CrossIndustry {
              description
              class
            }
            Hospitality {
              description
              class
            }
            SharedServices {
              description
              class
            }
          }
        }
      }
    `
  );
  return (
    <Box {...sectionWrapper}>
      {Data.hostingJson.INFO_SOLUTION2[name].map((item, index) => (
        <div
          key={`solution-${index}`}
          className={`multi_sections_container ${item.class}`}
        >
          <Box className="section_background_color">
            <Container>
              <Box {...contentArea}>
                <Fade bottom cascade>
                  {/* <Heading
                    {...title}
                    className="text_center"
                    content="Overview"
                  /> */}
                  <Text
                    {...description}
                    className="text_center"
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                </Fade>
                {/* <div className="empty_space40" />
                <div className="empty_space40" /> */}
              </Box>
            </Container>
          </Box>
          <Box>
            <Container>
              <div className="empty_space20" />
              <Fade bottom cascade>
                <IconFloatingSolutionCapability name={name} />
              </Fade>
              <div className="empty_space20" />
            </Container>
          </Box>
          <Box className="section_background_blue benefit_section">
            <BgImageDark />
            <Container>
              <div className="empty_space20" />
              <Fade bottom cascade>
                <IconFloatingSolutionBenefit name={name} />
              </Fade>
              <div className="empty_space20" />
            </Container>
          </Box>
        </div>
      ))}
    </Box>
  );
};

InfoSolutionSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  contentArea: PropTypes.object,
  imageArea: PropTypes.object,
  imageAreaRow: PropTypes.object,
  imageWrapper: PropTypes.object,
  imageWrapperOne: PropTypes.object,
  imageWrapperTwo: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  box_size: PropTypes.object,
};

InfoSolutionSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    // pt: ['25px', '25px', '25px', '30px', '30px'],
    // pb: ['60px', '80px', '40px', '80px', '80px'],
    id: 'info_solution',
  },
  secTitleWrapper: {
    mb: ['50px', '60px', '60px', '75px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#eb4d4b',
    mb: '10px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '15px',
  },
  textArea: {
    width: ['100%', '100%', '100%', '49%', '49%'],
  },
  contentArea: {
    width: ['100%', '100%', '80%', '80%', '80%'],
    margin: '0 auto',
  },
  imageArea: {
    width: ['100%', '100%', '50%'],
    flexBox: true,
    flexDirection: 'row-reverse',
  },
  imageWrapper: {
    boxShadow: 'none',
  },
  imageWrapperOne: {
    ml: '-200px',
  },
  imageWrapperTwo: {
    alignSelf: 'flex-end',
    mb: '-60px',
  },
  title: {
    fontSize: ['30px', '30px', '35px', '40px', '45px'],
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '15px',
    pt: '25px',
  },
  description: {
    fontSize: ['16px', '18px', '18px', '18px', '18px'],
    // color: '#343d48cc',
    color: '#252525',
    lineHeight: '1.5',
    pb: '40px',
    pt: '40px',
    mt: '0',
    mb: '0',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    height: `{5}`,
  },
  box_size: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
};

export default InfoSolutionSection;
