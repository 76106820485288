import React from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { hostingTheme } from 'common/src/theme/hosting';
import {
  GlobalStyle,
  ContentWrapper,
} from '../../containers/Hosting/hosting.style';
import { ResetCSS } from 'common/src/assets/css/style';
import Navbar from '../../containers/Hosting/Navbar';
import Footer from '../../containers/Hosting/Footer';
import Ctasection from '../../containers/Hosting/Ctasection';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { ParallaxProvider } from 'react-scroll-parallax';
import SEO from '../../components/seo';

import BannerSectionSolution from '../../containers/Hosting/Banner/BannerSolution';
import InfoSolutionSection from '../../containers/Hosting/InfoSolution';
import TopRibbon from '../../containers/Hosting/TopRibbon';

const RealEstatePage = () => {
  const name = 'RealEstate';
  return (
    <ThemeProvider theme={hostingTheme}>
      <ParallaxProvider>
        <SEO
          title="Automate your real estate business"
          description="Real estate & mortgage companies are buried in loads of paperwork. RAPFlow and RAPBot automate such tasks and achieve peak operational efficiency."
        />

        <ResetCSS />
        <GlobalStyle />
        <TopRibbon />
        <ContentWrapper className="RealEstatePage">
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <BannerSectionSolution name={name} />
          <InfoSolutionSection name={name} />
          <Ctasection name={name} />
          <Footer />
        </ContentWrapper>
      </ParallaxProvider>
    </ThemeProvider>
  );
};

export default RealEstatePage;
